import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import CardTestimonial from '../components/CardTestimonial'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Slider from 'react-slick'

class TestimonialsOverviewRoute extends React.Component {
    render() {
        const testimonialsOverviewData = this.props.data
        const title = testimonialsOverviewData.kontentItemSiteMetadata.elements.title.value
        const kontentItemTestimonialsOverviewPage = testimonialsOverviewData.kontentItemTestimonialsOverviewPage
        var subtitle = kontentItemTestimonialsOverviewPage.elements.title.value
        var metaDescription = kontentItemTestimonialsOverviewPage.elements.meta_description.value
        var description = kontentItemTestimonialsOverviewPage.elements.description.value
        var cooperation = kontentItemTestimonialsOverviewPage.elements.cooperation.value

        const items = []
        const testimonials = testimonialsOverviewData.allKontentItemTestimonial.nodes

        testimonials.forEach(testimonial => {
            items.push(<CardTestimonial data={testimonial} key={testimonial.id} />)
        })

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={metaDescription} />
                    <Header />
                    <PageHeading data={subtitle} />
                    <section className="references">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-heading">
                                        <h2>{metaDescription}</h2>
                                        <RichTextElement
                                            value={description} />
                                    </div>
                                </div>
                                <div className="col-lg-10 offset-lg-1">
                                    <Slider {...settings}>
                                        {items}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="references">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <RichTextElement
                                        value={cooperation} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default TestimonialsOverviewRoute

export const pageQuery = graphql`
  query TestimonialsOverviewQuery($codename: String!) {
                        kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
                        elements {
                        subtitle {
                        value
                    }
        title {
                        value
                    }
      }
    }
    kontentItemTestimonialsOverviewPage(system: {codename: {eq: $codename}}) {
                        id
      system {
                        id
                    }
      elements {
                        description {
                        value
                    }
        meta_description  {
                        value
                    }
        title {
                        value
                    }
        cooperation {
                        value
                    }
      }
    }
    allKontentItemTestimonial(filter: {preferred_language: {eq: "en-US"}}) {
                        nodes {
                        id
        elements {
                        slug {
                        value
                    }
          name {
                        value
                    }
          description {
                        value
                    }
        }
      }
    }
  }
`
