import React from 'react'
import { RichTextElement } from "@kentico/gatsby-kontent-components"

class CardTestimonials extends React.Component {
    render() {
        const testimonialData = this.props.data
        const testimonialElements = testimonialData.elements
        const name = testimonialElements.name.value
        const description = testimonialElements.description.value

        return (
            <div className="item">
                <div className="testimonial-item">
                    <h3>{name}</h3>
                    <RichTextElement
                        value={description} />
                </div>
            </div>
        )
    }
}

export default CardTestimonials
